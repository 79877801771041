<template>
  <div class="payment-status container text-center">
    <template v-if="$route.query.state.indexOf('success') > -1">
      <img src="../../../static/images/payment/info-success.svg"
           v-if="false" alt="paymentSuccess">
      <p>{{$t('order.state.success')}}</p>
      <p>
        <button class="btn default">{{$t('order.button.share')}}</button>
        <router-link to="/order-list">
          <button class="btn primary">{{$t('order.button.check')}}</button>
        </router-link>
      </p>
    </template>
    <template v-else>
      <img src="../../../static/images/payment/info-error.svg" alt="paymentFailed">
      <p>{{$t('order.state.failed')}}</p>
      <p>
        <router-link :to="{name: 'shelf'}">
          <button class="btn default">{{$t('order.button.back')}}</button>
        </router-link>
        <button class="btn primary" @click="$router.back()">{{$t('order.button.trade')}}</button>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'payment-status',
  data () {
    return {}
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
.payment-status {
  margin-top: 2rem;
  img {
    @media (max-width: 767px) {
      width: 150px;
    }
  }
  margin-bottom: 30px;
}
</style>
